import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/style.css";
import {
  Card,
  Form,
  Button,
  Toast,
  Carousel,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";
import mql5 from "../images/mql5_logo.jpg";
import upwork from "../images/upwork.png";
import hedge_specs from "../images/hedge_specs.png";
import hedge_demo from "../images/hedge_demo.png";
import profile_specs from "../images/profile_specs.png";
import profile_demo from "../images/profile_demo.png";
import upwork_test_1 from "../images/upwork_test_1.png";
import upwork_test_2 from "../images/upwork_test_2.png";
import upwork_test_3 from "../images/upwork_test_3.png";
import test_1 from "../images/test_1.png";
import test_2 from "../images/test_2.png";
import test_3 from "../images/test_3.png";

function Projects() {
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [budget, setBudget] = useState("");
  const [clientName, setClientName] = useState("");
  const [TG, setTG] = useState("");
  const [Email, setEmail] = useState("");
  const [files, setFiles] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handleTGChange = (e) => setTG(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleBudgetChange = (e) => setBudget(e.target.value);
  const handleDescChange = (e) => setDescription(e.target.value);
  const handlePNameChange = (e) => setProjectName(e.target.value);
  const handleCNameChange = (e) => setClientName(e.target.value);
  const handleFileChange = (e) => setFiles(e.target.files);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tg = {
      token: "5007154718:AAHWEu2vCue-1hPS1iZqbWasSg5fywVvjB0",
      chat_id: "1380098782",
    };

    const message = `NEW PROJECT ENQUIRY\n=====================\nProject name: ${projectName}\nProject Description: ${description}\nBudget: ${budget}\nClient name: ${clientName}\nTG: @${TG.replace(
      /@/,
      ""
    )}\nEmail: ${Email}`;

    try {
      // Send text message
      await axios.get(`https://api.telegram.org/bot${tg.token}/sendMessage`, {
        params: {
          chat_id: tg.chat_id,
          text: message,
        },
      });

      // Handle file uploads
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const formData = new FormData();
          formData.append("chat_id", tg.chat_id);
          formData.append("caption", message);

          // Determine file type and choose appropriate method
          if (file.type.startsWith("image/")) {
            formData.append("photo", file);

            await axios.post(
              `https://api.telegram.org/bot${tg.token}/sendPhoto`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } else {
            formData.append("document", file);

            await axios.post(
              `https://api.telegram.org/bot${tg.token}/sendDocument`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          }
        }
      }

      // Show success toast and hide error toast
      setShowSuccessToast(true);
      setShowErrorToast(false);
    } catch (error) {
      console.error("Error sending message or files:", error);
      // Show error toast and hide success toast
      setShowErrorToast(true);
      setShowSuccessToast(false);
    }
  };

  const projects = [
    {
      beforeImg: hedge_specs,
      afterImg: hedge_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: profile_specs,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: upwork_test_1,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: upwork_test_2,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: upwork_test_3,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: test_1,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: test_2,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
    {
      beforeImg: test_3,
      afterImg: profile_demo,
      title: "Project 2",
      description: "Short description of project 2.",
    },
  ];

  return (
    <>
      <Navbar />
      <Container className="mt-5 mb-5">
        {/* Hire Us Section */}
        <section className="hire-us-section mb-5 mx-auto">
          <h1 className="text-center mb-4 text-white">
            Hire Us for Your Custom Projects using any of the options below
          </h1>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={12}>
              <Card className="bg-dark border-primary p-4 mb-4">
                <Card.Header>
                  <h1 className="text-white">1.</h1>
                </Card.Header>
                <Card.Body className="text-center text-white">
                  <img
                    src={mql5}
                    width="200"
                    height="auto"
                    className="mb-3 img-fluid"
                    alt="MQL5"
                  />
                  <p>
                    Click the button below and fill in the details for your
                    project on the MQL5 order page. In the section that says "Do
                    you want to create a personal order to a selected
                    developer?", select "Yes" and enter the developer's name as
                    "PerpetualRalph"
                  </p>
                  <Button
                    href="https://www.mql5.com/en/job/new"
                    target="_blank"
                  >
                    Hire
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={8} lg={12}>
              <Card className="bg-dark border-primary p-4 mb-4">
                <Card.Header>
                  <h1 className="text-white">2.</h1>
                </Card.Header>
                <Card.Body className="text-center text-white">
                  <img
                    src={upwork}
                    width="200"
                    height="auto"
                    className="mb-3 img-fluid"
                    alt="Upwork"
                  />
                  <p>Click the button below.</p>
                  <Button
                    href="https://www.upwork.com/services/product/development-it-well-coded-forex-expert-advisors-and-indicators-in-mt4-and-mt5-1413723169750134784?ref=project_share"
                    target="_blank"
                    className="mt-3"
                  >
                    Hire
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Custom Projects Form */}
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={12}>
            <Card className="bg-dark border-primary p-4 mb-4">
              <Card.Header>
                <h1 className="text-white">3.</h1>
              </Card.Header>
              <Card.Body>
                <h1 className="text-center mb-4 text-white">
                  Reach us directly with details for a Custom Project
                </h1>
                <Form onSubmit={handleSubmit}>
                  <fieldset>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">
                        Project Name:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="Project-name"
                        name="Project-name"
                        onChange={handlePNameChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">Budget:</Form.Label>
                      <Form.Control
                        type="number"
                        id="budget"
                        name="budget"
                        onChange={handleBudgetChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">
                        Client Name:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="Client-name"
                        name="Client-name"
                        onChange={handleCNameChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">Email:</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleEmailChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">TG:</Form.Label>
                      <Form.Control
                        type="text"
                        id="TG"
                        name="TG"
                        onChange={handleTGChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">
                        Project Description:
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="description"
                        name="description"
                        onChange={handleDescChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="text-white">
                        Upload Files:
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="files"
                        name="files"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary w-100">
                      Submit
                    </Button>
                  </fieldset>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Carousel Section */}
        <section>
          <h2 className="text-center text-white mb-4">Recent Projects</h2>
          <Carousel
            className="custom-carousel"
            controls={true}
            indicators={false}
            interval={null}
          >
            {projects.map((project, index) => (
              <Carousel.Item key={index}>
                <Row className="justify-content-center">
                  <Col xs={12} md={8} lg={6}>
                    <Card className="bg-dark border-primary p-4">
                      <Card.Img
                        variant="top"
                        src={project.beforeImg}
                        className="img-fluid mb-3"
                        alt={project.title}
                      />
                      {/* <Card.Img
                        variant="top"
                        src={project.afterImg}
                        className="img-fluid mb-3"
                        alt={project.title}
                      /> */}
                      {/* <Card.Body>
                        <Card.Title className="text-white">
                          {project.title}
                        </Card.Title>
                        <Card.Text className="text-white">
                          {project.description}
                        </Card.Text>
                      </Card.Body> */}
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </section>
      </Container>

      {/* Toast Notifications */}
      <Toast
        onClose={() => setShowSuccessToast(false)}
        show={showSuccessToast}
        delay={3000}
        autohide
        bg="success"
        className="position-fixed bottom-0 end-0 m-3"
      >
        <Toast.Body className="text-white">
          Your message has been sent successfully!
        </Toast.Body>
      </Toast>

      <Toast
        onClose={() => setShowErrorToast(false)}
        show={showErrorToast}
        delay={3000}
        autohide
        bg="danger"
        className="position-fixed bottom-0 end-0 m-3"
      >
        <Toast.Body className="text-white">
          There was an error sending your message.
        </Toast.Body>
      </Toast>

      <Footer />
    </>
  );
}

export default Projects;
