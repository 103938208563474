import React from "react";
import Navbar from "./Navbar";
import {
  FaTelegram,
  FaWhatsapp,
  FaTwitter,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";
import "../styles/style.css";
import Footer from "./Footer";

function Contact() {
  return (
    <>
      <Navbar />
      <div className="contact-container">
        <h1 className="sm-header">
          Reach or follow us on any of our social media platforms
        </h1>
        <a href="https://t.me/jarvistrade_ai" id="telegram" target="_blank">
          <FaTelegram className="sm-icon" />
        </a>
        {/* <a href="https://wa.me/+2348072708424" id="whatsapp" target="_blank">
          <FaWhatsapp className="sm-icon" />
        </a> */}
        <a
          href="https://twitter.com/jarvistrade_ai"
          id="twitter"
          target="_blank"
        >
          <FaTwitter className="sm-icon" />
        </a>
        <a
          href="https://www.tiktok.com/@jarvistrade.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTiktok className="sm-icon" />
        </a>
        <a
          href="https://www.youtube.com/@jarvistrade6558"
          id="youtube"
          target="_blank"
        >
          <FaYoutube className="sm-icon" />
        </a>
      </div>

      <Footer className="footer" />
    </>
  );
}

export default Contact;
