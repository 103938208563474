import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/style.css";

function TOS() {
  return (
    <>
      <Navbar />
      <div className="policy-container">
        <h1>TERMS OF SERVICE</h1>
        <p>
          We retain the right to deny service to anyone, at any time, without
          explanation. You acknowledge that you will not reproduce, duplicate,
          or exploit any part of our services, including the website, without
          our explicit written consent. The headings in this agreement are for
          convenience only and do not limit or affect the terms in any way.
        </p>

        <p>
          Trading foreign exchange on margin carries significant risks and may
          not be suitable for all investors. The high leverage can work against
          you, and you could lose more than your initial investment. Before
          trading, carefully consider your investment goals, experience, and
          risk tolerance. Seek independent financial advice if you have any
          doubts.
        </p>

        <p>
          We disclaim responsibility for the accuracy, completeness, or
          timeliness of information on our site. The material provided is for
          general information only and should not be relied upon for
          decision-making without consulting more accurate and up-to-date
          sources. We reserve the right to modify the site's content at any time
          without notice and have no obligation to update the information. You
          acknowledge that it is your responsibility to monitor changes to our
          site.
        </p>

        <p>
          Prices for our products are subject to change without notice, and we
          reserve the right to modify or discontinue our services at any time.
          We will not be liable for any modifications, price changes, or
          discontinuation of our services.
        </p>

        <p>
          Certain products or services may be available exclusively online and
          have limited quantities. We reserve the right to limit sales to any
          person, region, or jurisdiction and to discontinue products at any
          time. All product descriptions and pricing are subject to change
          without notice.
        </p>

        <p>
          We reserve the right to refuse any order and limit quantities
          purchased per person or household. We may cancel orders that appear to
          be placed by dealers, resellers, or distributors. Third-party links on
          our site may direct you to unrelated websites, and we are not
          responsible for their content or accuracy.
        </p>

        <p>
          Any comments, feedback, or submissions you send us become our
          property, and we may use them without restriction. We may monitor,
          edit, or remove content that violates our Terms and Conditions.
        </p>

        <p>
          These Terms and Conditions remain effective until terminated by either
          party. You may terminate this agreement by notifying us that you no
          longer wish to use our services. We may terminate this agreement if
          you fail to comply with any term or provision.
        </p>

        <p>
          We reserve the right to update these Terms and Conditions at any time
          without notice. Your continued use of our services following any
          changes to these Terms and Conditions constitutes acceptance of those
          changes.
        </p>

        <p>
          If you have questions about our Terms and Conditions, please contact
          us at{" "}
          <a href="mailto:contact@jarvistrade.io">contact@jarvistrade.io</a>.
        </p>
      </div>
      <Footer className="footer" />
    </>
  );
}

export default TOS;
