import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaShoppingCart } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../styles/style.css";

const home = "https://apps.jarvistrade.io";

function Product({ product }) {
  return (
    <div className="flip-card mx-auto">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img
            src={home + product.image}
            alt={product.name}
            className="product-image"
          />
          <div className="card-footer product-name mt-3 text-white">
            {product.name}
          </div>
          <div className="card-footer product-platform mt-2">
            {product.platform} - ${product.price}
          </div>
        </div>
        <div className="flip-card-back">
          <h1 className="card-body product-name mb-1">{product.name}</h1>
          <p className="card-body product-description mt-1">
            {product.description}
          </p>
          <Link to={product.demo} className="product-more" target="_blank">
            Read more...
          </Link>

          <Link to={product.link} className="product-btn" target="_blank">
            <FaShoppingCart />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Product;
