import React from "react";
import Navbar from "./Navbar";
import { FaTelegram, FaWhatsapp, FaTwitter, FaYoutube } from "react-icons/fa";
import "../styles/style.css";
import Footer from "./Footer";

function Contact() {
  return (
    <>
      <Navbar />
      <div className="notfound-container">
        <h1>
          <strong>Page Not Found</strong>
        </h1>
        <h3>The requested page was not found</h3>
      </div>

      <Footer className="footer" />
    </>
  );
}

export default Contact;
