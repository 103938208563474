import React from "react";
import { FaTelegram, FaTwitter, FaTiktok, FaYoutube } from "react-icons/fa";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div
        className="container  p-3"
        style={{ minHeight: "1em", fontSize: "small" }}
      >
        <div className="row p-1">
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase">Contact Us</h5>
            <ul className="list-unstyled">
              Email:
              <li>
                <a className="text-white" href="mailto:support@jarvistrade.io">
                  contact@jarvistrade.io
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="mailto:smartmoneycreed@gmail.com"
                >
                  smartmoneycreed@gmail.com
                </a>
              </li>
            </ul>

            <ul className="list-unstyled">
              Address:
              <li>
                <a className="text-white">
                  18, Victorious Estate, Ayobo, Lagos, Nigeria
                </a>
              </li>
            </ul>

            <ul className="list-unstyled">
              Phone:
              <li>
                <a className="text-white">+2348072708424</a>
              </li>
              <li>
                <a className="text-white">+2348137357760</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4 text-center">
            <h5 className="text-uppercase">Follow Us</h5>
            <ul className="list-unstyled d-flex justify-content-center">
              <li className="me-3">
                <a
                  href="https://t.me/jarvistrade_ai"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTelegram size="2em" />
                </a>
              </li>
              <li className="me-3">
                <a
                  href="https://x.com/jarvistrade_ai"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter size="2em" />
                </a>
              </li>
              <li className="me-3">
                <a
                  href="https://www.tiktok.com/@jarvistrade.ai"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTiktok size="2em" />
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@jarvistrade6558"
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube size="2em" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="text-uppercase text-white">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="text-white">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/jobs" className="text-white">
                  Jobs
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-white">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-white">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/refund" className="text-white">
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="/tos" className="text-white">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-1">
          <small>© 2024 JarvisThink Innovations. All rights reserved.</small>
        </div>
      </div>
    </div>
  );
};

export default Footer;
