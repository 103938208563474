import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/style.css";
import hero from "../images/hero.jpg";

function About() {
  return (
    <>
      <Navbar />
      <div className="about-container" style={{ marginBottom: "0" }}>
        <section>
          <p>
            Welcome to Jarvistrade, where we are on a mission to redefine the
            trading experience for traders worldwide. Our journey began in 2021
            when recognizing the immense potential for automated trading systems
            to transform the industry, we set out on a path to make trading more
            accessible, efficient, and profitable.
          </p>
          <p>
            Our vision at Jarvistrade is clear: we aim to be the foremost
            provider of trading bots that empower every trader, from beginners
            to seasoned professionals, to achieve their financial goals. We are
            committed to staying at the cutting edge of technological innovation
            within the trading industry. Our vision is to continually enhance
            our products to meet the ever-evolving needs of traders, ensuring
            that they have the tools required for success.
          </p>
          <p>
            Our journey has evolved into a collaborative effort, with a team of
            experts who are committed to creating the most effective and
            profitable trading bots in the market. We continuously strive to
            refine our products and services, with an unwavering focus on
            providing traders with the tools they need to succeed.
          </p>
          <p>
            Join us at Jarvistrade and embark on a trading journey where
            innovation, reliability, and profitability converge. Together, we
            are shaping the future of trading, one algorithm at a time.
          </p>
        </section>
      </div>
      <Footer className="footer" />
    </>
  );
}

export default About;
