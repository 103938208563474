import React, { useState, useEffect } from "react";
import "../styles/style.css";
import hero from "../images/hero.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Product from "./Product";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const Bots = () => {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://apps.jarvistrade.io/copier/api/bots/")
      .then((response) => {
        if (Array.isArray(response.data)) {
          console.log(response.data);
          setBots(response.data);
        } else {
          console.error("Response data is not an array:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Navbar />
      <div>
        {loading ? (
          <div className="loading-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading bots...</p>
          </div>
        ) : (
          <div className="product-grid">
            {bots.map((bot) => (
              <Product key={bot.id} product={bot} />
            ))}
          </div>
        )}
      </div>
      <Footer className="footer" />
    </>
  );
};

export default Bots;
