import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/style.css";

function Privacy() {
  return (
    <>
      <Navbar />
      <div className="policy-container">
        <h1>PRIVACY POLICY</h1>
        <p>
          We collect personal information from visitors to our website and blog
          to provide a better experience and improve our services. When you
          register, order, or subscribe on our site, you may be asked to provide
          your name, email address, mailing address, or other details.
        </p>

        <p>We collect information when you:</p>
        <ul>
          <li>Register on our site</li>
          <li>Place an order</li>
          <li>Subscribe to our newsletter</li>
          <li>Enter information on our site</li>
        </ul>

        <p>We use your information to:</p>
        <ul>
          <li>
            Personalize your experience and deliver content and products that
            interest you
          </li>
          <li>Improve our website and services</li>
          <li>Respond to customer service requests</li>
          <li>Process transactions quickly</li>
        </ul>

        <p>
          Your personal information is secure behind our networks, accessible
          only to authorized personnel who must keep it confidential. We use
          Secure Socket Layer (SSL) technology to encrypt sensitive information
          and implement various security measures to protect your data. Our
          transactions are processed through a secure gateway provider and not
          stored on our servers.
        </p>

        <p>
          We do not sell, trade, or transfer your personal information to
          outside parties without your consent, except to our website hosting
          partners and other parties that assist us in operating our website and
          conducting our business, provided they keep your information
          confidential and are bound by appropriate confidentiality agreements.
          We may release information to comply with the law, enforce our site
          policies, or protect our rights or others' rights, property, or
          safety.
        </p>

        <p>
          Our site does not honor Do Not Track signals or plant cookies, and we
          do not allow third-party behavioral tracking. We collect your email
          address to send information, respond to inquiries, and market to our
          mailing list or continue sending emails after the original transaction
          has occurred.
        </p>

        <p>
          If you have questions about our privacy policy, please contact us at{" "}
          <a href="mailto:contact@jarvistrade.io">contact@jarvistrade.io</a>.
          {/* This Privacy Policy was last updated on June 30, 2024. */}
        </p>
      </div>
      <Footer className="footer" />
    </>
  );
}

export default Privacy;
