import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import hero from "../images/hero.jpg";
import hero3 from "../images/hero3.jpg";
import hero2 from "../images/hero2.jpg";
import apps from "../images/apps.png";
import indicators from "../images/indicators.png";
import bots from "../images/bots.png";
import custom from "../images/specs.png";
import platforms from "../images/platforms.png";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Carousel,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "../styles/hero.css";
import "../styles/services.css";
import "../styles/testimonials.css";
import { Link } from "react-router-dom";
import {
  FaAppStore,
  FaChartLine,
  FaRobot,
  FaThLarge,
  FaTools,
} from "react-icons/fa";

function Home() {
  const Hero = {
    titles: [
      "TAME THE MARKETS WITH TOP TRADING TOOLS",
      "GET PREMIUM TRADING INDICATORS & BOTS",
      "REQUEST CUSTOM TRADING SOLUTIONS",
    ],
    messages: [
      `Unlock the full potential of trading with our automation tools at Jarvistrade. We offer premium trading indicators, advanced trading bots, and innovative trading applications compatible with major platforms like MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. Whether you need trading tools for MT4, MT5, or TradingView, our solutions can be converted and adapted to fit your preferred platform, giving you the flexibility to trade with precision and confidence.`,
      `Enhance your trading strategy with our top-rated trading indicators and powerful trading bots. Our tools are compatible with popular trading platforms such as MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. Explore our collection of premium trading indicators and bots, and benefit from our ability to convert and tailor these tools for different trading platforms to boost your trading performance.`,
      `Need a unique solution tailored to your trading style? Request custom trading tools from Jarvistrade. Our expert team can develop custom indicators and trading systems for MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. We offer the flexibility to convert tools and applications across various trading platforms to meet your specific needs and goals.`,
    ],
    images: [hero, hero3, hero2],
  };

  const services = [
    {
      title: "Applications",
      description: `Explore our range of trading applications designed for retail traders. Our apps are compatible with major platforms like MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. We build powerful applications using Python, opening up endless possibilities for AI/ML powered trading tools. Our applications can be converted between these platforms, providing you with versatile and cutting-edge solutions for your trading needs.`,
      icon: <FaThLarge />,
      link: "/market/apps",
      image: apps,
    },
    {
      title: "Indicators",
      description: `Access high-quality trading indicators compatible with MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. Our indicators offer real-time insights and accurate signals, and we can convert them to fit your preferred trading platform for optimal performance.`,
      icon: <FaChartLine />,
      link: "/market/indicators",
      image: indicators,
    },
    {
      title: "Bots",
      description: `Automate your trading with our advanced trading bots. Our bots are designed for MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView, and we offer the capability to convert these bots between platforms to suit your trading preferences.`,
      icon: <FaRobot />,
      link: "/market/bots",
      image: bots,
    },
    {
      title: "Custom Trading Tools",
      description: `Request custom trading tools tailored to your specific needs. We develop custom indicators and trading systems for MetaTrader 4 (MT4), MetaTrader 5 (MT5), and TradingView. Our team can also convert and adapt these tools across different trading platforms to fit your requirements.`,
      icon: <FaTools />,
      link: "/jobs",
      image: custom,
    },
  ];

  const testimonials = [
    {
      quote:
        "Amazing company to work with. Their trading tools and services are outstanding!",
      author: "A. M., Trader",
    },
    {
      quote:
        "Fantastic experience! The team at JarvisTrade is knowledgeable, patient, and courteous. Will definitely use their services again.",
      author: "S. B., Investor",
    },
    {
      quote:
        "JarvisTrade delivered quickly at a great price. A small miscommunication on my end was resolved right away. Overall, a good experience.",
      author: "W. T., Financial Analyst",
    },
    {
      quote: "Thank you, JarvisTrade!",
      author: "S. H., Entrepreneur",
    },
    {
      quote: "Top-notch service from JarvisTrade.",
      author: "O. P., Investor",
    },
    {
      quote:
        "Wonderful work as expected! JarvisTrade is fast, fluent, and professional. Highly recommended!",
      author: "S. H., Small Business Owner",
    },
    {
      quote:
        "JarvisTrade is the best! This is my second time working with them, and I'm completely satisfied. I’ll hire them again soon!",
      author: "S. H., Business Consultant",
    },
    {
      quote:
        "Professional and capable team! JarvisTrade delivers on time with no headaches. I've worked with many developers, but they are by far the most talented. Definitely recommended!",
      author: "S. H., Technical Analyst",
    },
    {
      quote:
        "Jarvistrade's trading indicators have transformed my trading strategy. I'm now making more informed decisions and staying ahead of the market.",
      author: "Sarah T., Professional Trader",
    },
    {
      quote:
        "The custom trading bot from Jarvistrade has been a game-changer. It's incredibly accurate and has significantly boosted my profits.",
      author: "James K., Forex Trader",
    },
    {
      quote:
        "I highly recommend Jarvistrade's services. Their trading tools are top-notch, and their customer support is exceptional.",
      author: "Emily R., Day Trader",
    },
  ];

  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setContentIndex((prevIdx) => (prevIdx + 1) % Hero.images.length);
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  const heroTitle = Hero.titles[contentIndex];
  const heroContent = Hero.messages[contentIndex];
  const heroBG = Hero.images[contentIndex];

  return (
    <>
      <Navbar />
      <section
        className="hero mb-5"
        style={{ backgroundImage: `url(${heroBG})` }}
      >
        <div className="hero-overlay"></div>
        <div className="hero-content text-center text-white">
          <h1>{heroTitle}</h1>
          <h2>{heroContent}</h2>
          <img
            className="hero-platforms img-fluid mb-5"
            src={platforms}
            width="300"
            alt="Trading Platforms"
          />
        </div>
      </section>
      <section className="services-sliding-panels-section">
        <Container>
          <Row className="justify-content-center">
            {services.map((service, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <div className="service-panel mx-auto">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="service-image img-fluid"
                  />
                  <div className="service-content text-center">
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                    <OverlayTrigger
                      key={index}
                      placement="bottom"
                      overlay={<Tooltip>Explore {service.title}</Tooltip>}
                    >
                      <Link to={service.link}>
                        <Button className="service-btn">{service.icon}</Button>
                      </Link>
                    </OverlayTrigger>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="testimonials-section py-5">
        <Container>
          <Carousel>
            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <blockquote className="blockquote text-center">
                  <p className="mb-0">{testimonial.quote}</p>
                  <footer className="blockquote-footer mt-2">
                    {testimonial.author}
                  </footer>
                </blockquote>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </section>
      <Footer className="footer" />
    </>
  );
}

export default Home;
