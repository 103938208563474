import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../styles/style.css";

function Refund() {
  return (
    <>
      <Navbar />
      <div className="policy-container">
        <h1>REFUND POLICY</h1>
        <p>
          At JarvisTrade, we value customer satisfaction and stand by the
          quality of our products. However, due to the nature of our digital
          products (trading bots, indicators, and applications), we have
          established a fair and transparent refund policy to protect both our
          customers and our business.
        </p>

        <h2>1. Eligibility for Refunds</h2>
        <p>Refunds may be issued under the following circumstances:</p>
        <ul>
          <li>
            <strong>Non-Delivery of Product:</strong> If you did not receive the
            product after purchase due to technical issues or errors on our end.
          </li>
          <li>
            <strong>Major Defects:</strong> If the product you purchased is
            found to be significantly defective or non-functional, and the issue
            cannot be resolved within a reasonable period.
          </li>
          <li>
            <strong>Misrepresentation:</strong> If the product is significantly
            different from its description on our website or promotional
            materials.
          </li>
          <li>
            <strong>Unauthorized Payment:</strong> If you can provide evidence
            that your purchase was made fraudulently or without your
            authorization.
          </li>
        </ul>

        <h2>2. Non-Eligibility for Refunds</h2>
        <p>Refunds will not be issued under the following circumstances:</p>
        <ul>
          <li>
            <strong>Change of Mind:</strong> Refunds are not provided for
            customers who simply change their mind after purchase.
          </li>
          <li>
            <strong>Compatibility Issues:</strong> It is the customer’s
            responsibility to ensure that their system meets the required
            specifications before purchasing. Refunds will not be issued for
            incompatibility.
          </li>
          <li>
            <strong>Improper Use:</strong> Refunds will not be provided if the
            product has been altered, misused, or damaged due to negligence.
          </li>
          <li>
            <strong>Minor Bugs:</strong> Minor bugs or issues that do not affect
            the overall functionality of the product will not qualify for a
            refund. We provide support to resolve such issues.
          </li>
          <li>
            <strong>Third-Party Marketplace Purchases:</strong> If you purchased
            our product through a third-party marketplace, the refund process
            will be governed by the refund policy of that specific marketplace.
            Please refer to the marketplace's refund policy for more details.
          </li>
        </ul>

        <h2>3. Refund Request Process</h2>
        <p>
          To request a refund for purchases made directly through JarvisTrade,
          please follow these steps:
        </p>
        <ul>
          <li>
            <strong>Contact Us:</strong> Email our support team at{" "}
            <a href="mailto:contact@jarvistrade.io">contact@jarvistrade.io</a>{" "}
            within 7 days of your purchase. Please include your order number,
            the product name, and a detailed explanation of the issue.
          </li>
          <li>
            <strong>Assessment:</strong> Our team will assess your request and
            may ask for additional information or conduct troubleshooting steps
            with you.
          </li>
          <li>
            <strong>Resolution:</strong> If the issue cannot be resolved, and
            the request meets our refund criteria, we will process your refund
            within 10 business days.
          </li>
        </ul>

        <h2>4. Refund Method</h2>
        <p>
          Refunds will be issued through the original payment method used during
          the purchase. Please note that it may take additional time for the
          refund to reflect in your account depending on your bank or payment
          provider.
        </p>

        <h2>5. Amendments</h2>
        <p>
          JarvisTrade reserves the right to amend this refund policy at any time
          without prior notice. Any changes will be posted on this page, and the
          effective date will be updated accordingly.
        </p>

        <p>
          For any questions or concerns regarding our refund policy, please
          contact us at{" "}
          <a href="mailto:contact@jarvistrade.io">contact@jarvistrade.io</a>.
          We’re here to help and ensure that you have a positive experience with
          our products.
        </p>
      </div>
      <Footer className="footer" />
    </>
  );
}

export default Refund;
